module.exports = PostToolbarDirective;

PostToolbarDirective.$inject = [];
function PostToolbarDirective() {
    return {
        restrict: 'E',
        scope: {
            filters: '=',
            selectedPost: '=',
            stats: '=',
            savedSearch: '='
        },
        controller: PostToolbarController,
        template: require('./post-toolbar.html')
    };
}

PostToolbarController.$inject = [
    '$scope',
    // '$translate', // from saved searches
    // '_', // from saved searches
    // 'ModalService', // from saved searches
    // 'PostFilters', // from saved searches
    '$rootScope',
    'Notify',
    'PostLockService',
    '$state',
    'LoadingProgress',
    '$window',
    // 'NotificationEndpoint', // from saved searches
    // 'SavedSearchEndpoint' // from saved searches
];
function PostToolbarController(
    $scope,
    // $translate, // from saved searches
    // _, // from saved searches
    // ModalService, // from saved searches
    // PostFilters, // from saved searches
    $rootScope,
    Notify,
    PostLockService,
    $state,
    LoadingProgress,
    $window,
    // NotificationEndpoint, // from saved searches
    // SavedSearchEndpoint // from saved searches
) {
    $scope.setEditMode = setEditMode;
    $scope.savePost = savePost;
    $scope.hasPermission = $rootScope.hasPermission('Manage Posts');
    $scope.editEnabled = editEnabled;
    $scope.isLoading = LoadingProgress.getLoadingState;
    $scope.isSaving = LoadingProgress.getSavingState;
    $scope.editMode = editMode;
    $scope.cancel = cancel;
    $scope.hideOtherActions = hideOtherActions;
    $scope.showOtherActions = showOtherActions;
    $scope.filtersActive = false;

    // Test
    // From saved searches
    // $scope.showNotificationLink = true;
    // $scope.canEdit = false;
    // $scope.notification = false;
    // $scope.collectionView = 'map';
    // $scope.saveNotification = saveNotification;
    // $scope.removeNotification = removeNotification;
    // $scope.editSavedSearch = editSavedSearch;
    // $scope.deleteSavedSearch = deleteSavedSearch;
    // $scope.clearFilters = clearFilters;

    $scope.selectedLocation = $scope.filters.q;
    console.log('TOOLBAR');
    // console.log($scope.filters.q);
    // console.log($scope.canEdit);
    // console.log($scope.editSavedSearch);
    // console.log($scope.savedSearch);
    console.log($scope);
    // console.log($window);

    // activate();

    // function activate() {
    //     $scope.canEdit = canEdit($scope.savedSearch);

    //     NotificationEndpoint.query({set: $scope.savedSearch.id, ignore403: true, user: 'me'}, function (notifications) {
    //         // show link if subscription does not exist
    //         $scope.showNotificationLink = notifications.length === 0;
    //         if (notifications.length) {
    //             $scope.notification = notifications[0];
    //         }
    //     }, angular.noop);
    // }

    // // Check if we can edit
    // function canEdit(savedSearch) {
    //     return savedSearch && _.contains(savedSearch.allowed_privileges, 'update');
    // }

    // function editSavedSearch() {
    //     ModalService.openTemplate('<saved-search-editor saved-search="savedSearch"></saved-search-editor>', 'set.edit_search_settings', 'star', $scope, false, false);
    //     //$rootScope.$emit('savedSearchEditor:show', $scope.collection);
    // }

    // function deleteSavedSearch() {
    //     Notify.confirm('notify.savedsearch.delete_savedsearch_confirm').then(function () {
    //         SavedSearchEndpoint.delete({
    //             id: $scope.savedSearch.id
    //         }).$promise.then(function () {
    //             $state.go('posts.map.all');
    //             $rootScope.$broadcast('savedSearch:update');
    //         }, function (errorResponse) {
    //             Notify.apiErrors(errorResponse);
    //         });
    //     });
    // }

    // function saveNotification(savedSearch) {
    //     var notification = {set: savedSearch.id};

    //     NotificationEndpoint.save(notification).$promise.then(function (notification) {
    //         // No need to show the link after subscription
    //         $scope.showNotificationLink = false;
    //         $scope.notification = notification;
    //         Notify.notify('notify.notification.add', {set: savedSearch.name});
    //     });
    // }

    // function removeNotification() {
    //     Notify.confirm('notify.notification.delete_confirm').then(function () {
    //         NotificationEndpoint.delete($scope.notification).$promise.then(function (notification) {
    //             $scope.showNotificationLink = true;
    //             Notify.notify('notify.notification.destroy_notification_success', {name: notification.name});
    //         });
    //     });
    // }

    // function clearFilters() {
    //     PostFilters.clearFilters();
    //     PostFilters.setMode('all');
    // }

    // Bounkani location select
    $scope.changeBounkaniLocation = changeBounkaniLocation;

    // Tchologo location select
    $scope.changeTchologoLocation = changeTchologoLocation;


    function editEnabled() {
        if (!$scope.selectedPost || !$scope.hasPermission) {
            return false;
        }

        return $scope.selectedPost ? !PostLockService.isPostLockedForCurrentUser($scope.selectedPost) : false;
    }

    function savePost() {
        $rootScope.$broadcast('event:edit:post:data:mode:save');
    }

    function editMode() {
        return $state.$current.name === 'posts.data.edit';
    }

    function setEditMode() {
        if (editEnabled()) {
            $state.go('posts.data.edit', {postId: $scope.selectedPost.id});
        }
    }

    function cancel() {
        $state.go('posts.data.detail', {postId: $scope.selectedPost.id});
    }

    function hideOtherActions() {
        $scope.filtersActive = true;
    }

    function showOtherActions() {
        $scope.filtersActive = false;
    }

    function changeBounkaniLocation() {

        // Redirect the user to the saved filter location
        $window.location.href = $scope.bounkani.locationSelect;

    } // End function

    function changeTchologoLocation() {

        // Redirect the user to the saved filter location
        $window.location.href = $scope.tchologo.locationSelect;

    } // End function
}
