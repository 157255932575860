module.exports = Maps;

Maps.$inject = ['ConfigEndpoint', 'Leaflet', '_', 'CONST'];
function Maps(ConfigEndpoint, L, _, CONST) {

    // mapbox static tiles API styles
    // references:
    //   https://docs.mapbox.com/help/troubleshooting/migrate-legacy-static-tiles-api/#leaflet-implementations
    //   https://docs.mapbox.com/api/maps/#mapbox-styles
    function _mapboxStaticTiles(name, mapid) {
        return {
            name: name,
            url: 'https://api.mapbox.com/styles/v1/{mapid}/tiles/{z}/{x}/{y}?access_token={apikey}',
            layerOptions: {
                apikey: CONST.MAPBOX_API_KEY,
                tileSize: 512,
                maxZoom: 18,
                zoomOffset: -1,
                mapid: mapid,
                attribution: 'Proudly made by <strong>&copy; <a href="https://www.xsel-services.com/">XSEL Services</a></strong>'
            }
        };
    }

    var layers = {
        baselayers : {
            satellite: _mapboxStaticTiles('Satellite', 'mapbox/satellite-v9'),
            streets: _mapboxStaticTiles('Streets', 'mapbox/streets-v11'),
            hOSM: {
                name: 'Humanitarian',
                url: '//{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
                layerOptions: {
                    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>, &copy; <a href="http://hot.openstreetmap.org/">Humanitarian OpenStreetMap</a> | <a href="https://www.mapbox.com/feedback/" target="_blank">Improve the underlying map</a>'
                }
            }
        }
    };

    return {
        createMap: createMap,
        getLeafletConfig: getLeafletConfig,
        getBaseLayers: getBaseLayers,
        pointToLayer: pointToLayer,
        getConfig: getConfig,
        getLayer: getLayer,
        pointIcon: pointIcon,
        defaultConfig: defaultConfig,

        // Test function
        // checkZoomLevel: checkZoomLevel
    };

    function createMap(element) {
        return getLeafletConfig().then(function (config) {
            var map = L.map(element, config);
            map.attributionControl.setPrefix(false);
            map.zoomControl.setPosition('topleft');
            map.setMaxBounds([[-90,-360],[90,360]]);
            map.scrollWheelZoom.enable();
            map.on('popupopen', function (e) {
                var px = map.project(e.popup._latlng); // find the pixel location on the map where the popup anchor is
                px.y -= e.popup._container.clientHeight / 2; // find the height of the popup container, divide by 2, subtract from the Y axis of marker location
                map.panTo(map.unproject(px), {animate: true}); // pan to new center
            });

            // Add a layer control
            // L.control.layers(getBaseLayersForControl(), {}).addTo(map);
            var iconicSprite = require('ushahidi-platform-pattern-library/assets/img/iconic-sprite.svg');
            var resetButton  = L.easyButton({
                id: 'reset-button',
                position: 'topleft',
                type: 'replace',
                leafletClasses: true,
                states:[{
                    // specify different icons and responses for your button
                    stateName: 'reset-button',
                    onClick: function() {
                        var defaultview = defaultValues(config);
                        map.setView([defaultview.lat, defaultview.lon], defaultview.zoom);
                    },
                    title: 'Vue par défaut',
                    // title: 'Reset to default view',
                    icon: '<svg class="iconic" style="fill:black;"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="' + iconicSprite + '#home"></use></svg>'
                }]
            });

            // resetButton.addTo(map);

            // Test
            // ...
            // console.log('MAPS');
            // console.log(map.getZoom());

            // Resize the location's name after ay zoom
            // map.on('zoomend', function(ev) {

                // Get the current zoom level
                // let currentZoomLevel = map.getZoom();

                // Test
                // ...
                // console.log('zoom level ...');
                // console.log(currentZoomLevel); // ev is an event object (ZoomEnd in this case)

                // Call a function to change the location's name text size according to the given zoom level
                // let labelSize = labelFontSize(currentZoomLevel);

                // Test
                // ...
                // console.log('text size ...');
                // console.log(labelSize);

            // });

            // map.on('zoomend',function(e){
            //     let currentZoom = map.getZoom();

            //     // Current zoom level
            //     console.log(currentZoom);

            // }); // End event


            /**
             * ADD VILLAGES NAMES ON THE MAP
             */
            // KAKOTA
            var textLatLng = [9.8199585, -3.2785614];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'KAKOTA'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // ASSOUM 1
            var textLatLng = [9.2292407, -3.0644601];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'ASSOUM1'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // ASSOUM 1
            // var textLatLng = [9.3254132, -3.0339554]; // Original
            var textLatLng = [9.3154132, -3.0539554];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'BROMAKOTE'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // ASSOUM 1
            // var textLatLng = [8.7547022, -3.0380387]; // Original
            var textLatLng = [8.7447022, -3.0380387]; // [y, x]
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'DIHINTEDOUO'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // ASSOUM 1
            var textLatLng = [9.4110981, -2.822893];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'VARGBO'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // ASSOUM 1
            // var textLatLng = [8.7680099, -3.0157781]; // Original
            var textLatLng = [8.7880099, -3.0007781]; // [y, x]
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'KALTOUDOUO'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // ASSOUM 1
            var textLatLng = [8.9490538, -3.1814106];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'KOKPINGUE'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // ASSOUM 1
            var textLatLng = [9.2612029, -2.8948455];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'NIANDEGUE'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // ASSOUM 1
            // var textLatLng = [9.3389105, -2.9657149]; // Original
            var textLatLng = [9.3489105, -2.9557149]; // [y, x]
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'NOMIDOUO'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // ASSOUM 1
            var textLatLng = [9.4225047, -3.1066807];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'PANZARANI'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // ASSOUM 1
            var textLatLng = [9.4397866, -3.3353159];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'SAYE'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // ASSOUM 1
            var textLatLng = [9.0375436, -3.0052409];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'SIYALEDOUO'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // ASSOUM 1
            var textLatLng = [9.7979782, -3.3966518];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'TINGO-YALO'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // ASSOUM 1
            var textLatLng = [9.6605272, -3.281067];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'VARALE'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

















            // MANOGOTA
            var textLatLng = [9.2150096, -4.5423869];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'MANOGOTA'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // FASSELEMON
            var textLatLng = [9.2784667, -4.5313297];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'FASSELEMON'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // FANGAKAHA
            // var textLatLng = [9.5676514, -5.2043468]; // Original
            var textLatLng = [9.5376514, -5.2243468];
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'FANGAKAHA'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // GUEGNENIN
            var textLatLng = [9.1828131, -4.4781742];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'GUEGNENIN'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // DIAGBANASSO
            var textLatLng = [9.0763452, -4.6454522];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'DIAGBANASSO'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // BASSELE
            var textLatLng = [9.2692152, -4.7975665];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'BASSELE'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // LAMEKAHA 2
            var textLatLng = [9.3664223, -5.0555751];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'LAMEKAHA2'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // NAMBONKAHA
            var textLatLng = [9.740736, -5.1587827];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'NAMBONKAHA'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // KOUTIO
            // var textLatLng = [9.4605186, -4.9112601]; // Fake
            var textLatLng = [9.4834367, -5.1483467]; // Original 9.4834367, -5.1483467
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'KOUTIO'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // LINGUEKORO
            var textLatLng = [9.4266502, -4.6279998];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'LINGUEKORO'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // TCHEBINGUE
            var textLatLng = [9.5740263, -5.2076101];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'TCHEBINGUE'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // KALAKALA
            var textLatLng = [9.4615186, -4.9101601];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'KALAKALA'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // PARAKA
            var textLatLng = [9.3154066, -4.6154845];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'PARAKA'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);

            // DIEMBALA
            var textLatLng = [9.5169316, -5.008402];  
            var myTextLabel = L.marker(textLatLng, {
                icon: L.divIcon({
                    className: 'text-labels',   // Set class for CSS styling
                    html: 'DIEMBALA'
                }),
                zIndexOffset: 1000     // Make appear above other map features
            }).addTo(map);


            return map;
        });
    }

    function getLeafletConfig() {
        return getConfig().then(function (config) {
            var defaultLayer = layers.baselayers[config.default_view.baselayer];

            return angular.extend(defaultConfig(),
            {
                layers: [L.tileLayer(defaultLayer.url, defaultLayer.layerOptions)],
                center: [config.default_view.lat, config.default_view.lon],
                zoom: config.default_view.zoom,
                clustering: config.clustering
            });
        });
    }

    function getBaseLayers() {
        return layers.baselayers;
    }
    /* eslint-disable */
    function getBaseLayersForControl() {
        return _.chain(layers.baselayers)
        .values()
        .indexBy('name')
        .mapObject(function (layer) {
            return L.tileLayer(layer.url, layer.layerOptions);
        })
        .value();
    }
    /* eslint-enable */

    function getLayer(layerKey) {
        var layer = layers.baselayers[layerKey];
        return L.tileLayer(layer.url, layer.layerOptions);
    }

    function getConfig(fresh) {
        return ConfigEndpoint[fresh ? 'getFresh' : 'get']({ id: 'map' }).$promise.then(function (config) {
            // Handle legacy layers
            if (config.default_view.baselayer === 'MapQuest') {
                config.default_view.baselayer = 'streets';
            }
            if (config.default_view.baselayer === 'MapQuestAerial') {
                config.default_view.baselayer = 'satellite';
            }
            return config;
        });
    }

    function defaultConfig() {
        return {
            scrollWheelZoom: true,
            // center: [-1.2833, 36.8167], // Default to centered on Nairobi
            // zoom: 3, // Default
            center: [-4.108918726109778, 9.221404620134237], // Custom to centered on TCHOLOGO & BOUNANI
            zoom: 7, // Custom
            minZoom: 7,
            maxZoom: 18,
            layers: [L.tileLayer(layers.baselayers.streets.url, layers.baselayers.streets.layerOptions)]
        };
    }

    function pointToLayer(feature, latlng) {
        return L.marker(latlng, {
            icon: pointIcon(feature.properties['marker-color'])
        });
    }
    // Icon configuration
    function pointIcon(color, size, className) {
        // Test string to make sure that it does not contain injection
        color = (color && /^[a-zA-Z0-9#]+$/.test(color)) ? color : '#959595';
        size = size || [32, 32];
        var iconicSprite = require('ushahidi-platform-pattern-library/assets/img/iconic-sprite.svg');

        return L.divIcon({
            className: 'custom-map-marker ' + className,
            html: '<svg class="iconic" style="fill:' + color + ';"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="' + iconicSprite + '#map-marker"></use></svg><span class="iconic-bg" style="background-color:' + color + ';""></span>',
            iconSize: size,
            iconAnchor: [size[0] / 2, size[1]],
            popupAnchor: [0, 0 - size[1]]
        });
    }

    // default view is centered on nairobi
    function defaultValues(config) {
        return {
            lat: config.center[0],
            lon: config.center[1],
            zoom: config.zoom
        }
    }

    // function checkZoomLevel() {
    //     console.log('test');
    //     console.log(map.getZoom());
    // }


    // Update the locations's name text label according to the given zoom level
    // function labelFontSize(zoomLevel = 9) {

    //     // Set the default text font size
    //     let labelFontSize = (zoomLevel * 0.05);

    //     // Return the updated font size
    //     return labelFontSize;

    // } // End function

}
